import axios from 'axios';

interface Response<T> {
  data: T;
}
interface RequestOptions<T> {
  method: 'get' | 'post' | 'put' | 'delete';
  endpoint: string;
  data?: any;
  token?: string;
}

// const api = axios.create({
//   baseURL: 'http://localhost:1337/api',
// });
// export const urlBase = "http://localhost:1337";

const api = axios.create({
  baseURL: 'https://admin-homolog.tourin.tur.br/api',
});

// const api = axios.create({
//   baseURL: 'https://admin.tourin.tur.br/api',
// });

export const urlBase = "";

function request<T>(options: RequestOptions<T>) {
  return new Promise<Response<T>>(async (resolve, reject) => {
    try {
      const config: Record<string, any> = {
        method: options.method,
        url: options.endpoint,
      };
      if (options.data !== undefined) {
        config.data = options.data;
      }
      if (options.token) {
        config.headers = {
          Authorization: `Bearer ${options.token}`,
        };
      }
      const response = await api.request<Response<T>>(config);
      resolve(response.data);
    } catch (error) {
      console.error(error);
      reject({ data: null });
    }
  });
}


export default request;
