import { lazy } from "react";

/* Páginas */
const CidadesLista = lazy(() => import("../../views/cidades/listar"));
const ProgramacaoLista = lazy(() => import("../../views/cidades/programacao/listar"));
const ProgramacaoForm = lazy(() => import("../../views/cidades/programacao/form"));
const EventosLista = lazy(() => import("../../views/cidades/eventos/listar"));
const EventosForm = lazy(() => import("../../views/cidades/eventos/form"));
const RoteirosLista = lazy(() => import("../../views/cidades/roteiros/listar"));
const RotasForm = lazy(() => import("../../views/cidades/roteiros/form"));
const EventosEmpresas = lazy(() => import("../../views/cidades/eventos-empresas"));

const cidadesRoutes = [
  {
    path: "/cidades",
    element: <CidadesLista />,
    isPrivate: true,
  },
  {
    path: "/cidades/programacao/:id",
    element: <ProgramacaoLista />,
    isPrivate: true,
  },
  {
    path: "/cidades/programacao/form/:cidade",
    element: <ProgramacaoForm />,
    isPrivate: true,
  },
  {
    path: "/cidades/programacao/form/:cidade/:id",
    element: <ProgramacaoForm />,
    isPrivate: true,
  },
  {
    path: "/cidades/eventos/:id",
    element: <EventosLista />,
    isPrivate: true,
  },
  {
    path: "/cidades/eventos/form/:programacao",
    element: <EventosForm />,
    isPrivate: true,
  },
  {
    path: "/cidades/eventos/form/:programacao/:id",
    element: <EventosForm />,
    isPrivate: true,
  },
  {
    path: "/cidades/rotas/:cidade",
    element: <RoteirosLista />,
    isPrivate: true,
  },
  {
    path: "/cidades/rotas/form/:cidade",
    element: <RotasForm />,
    isPrivate: true,
  },
  {
    path: "/cidades/rotas/form/:cidade/:id",
    element: <RotasForm />,
    isPrivate: true,
  },
  {
    path: "/cidades/eventos-empresas/:id",
    element: <EventosEmpresas />,
    isPrivate: true,
  },
];

export default cidadesRoutes;